/**
 * Inline javascript tags open us up to XSS vulnerabilities.
 * To avoid that, we instead create html tags marked up with js data (generated by content_security_policy_helper.rb).
 * This script retrieves and parses the data and assigns it to variables,
 * preserving the original scope and values.
 *
 * =====================
 * Usage Types
 * =====================
 *
 * 1. There are several types that are handled:
 *    a. strings
 *    b. objects (also handles all levels of nesting)
 *    c. handlebars
 *
 * 2. To see how to generate html markup that is utilized for this script
 *    see comments in content_security_policy_helper.rb
 *
 * side note: This file is not using any jQuery in order to be able to extract
 * inline javascripts (i.e. rollbar) to a separate file which is loaded before
 * jQuery is available.
 */

var Greenhouse = Greenhouse || {};

Greenhouse.ContentSecurityPolicyHelper = function (selector) {
  var key = selector.getAttribute('data-key'),
    value = selector.getAttribute('data-value'),
    type = selector.getAttribute('data-type'),
    handlebars = selector.getAttribute('data-handlebars');

  this.setObject = function () {
    // Objects are built by iterating through a string that is split by '.'
    // (ex: "Config.EmailTypes.nameTokenMap")
    // This also works for strings that aren't separated by dots (i.e. "current_user_id")
    var keys = key.split('.'),
      lastKey = keys.pop(),
      parentObject = buildObject(keys);

    setValue(lastKey, parentObject);
  };

  function buildObject(keys) {
    var parentObject = window,
      key;

    for (var i = 0; i < keys.length; i++) {
      key = keys[i];

      parentObject[key] = parentObject[key] || {};
      parentObject = parentObject[key];
    }

    return parentObject;
  }

  function setValue(key, object) {
    object = object || window;

    if (handlebars) {
      value = Handlebars.compile(value);
      object[key] = value;
    } else {
      switch (type) {
        case 'json':
          object[key] = JSON.parse(value);
          break;
        case 'string':
          if (value === 'null') {
            object[key] = null;
          } else {
            object[key] = value.toString();
          }
          break;
        case 'boolean':
          object[key] = JSON.parse(value);
          break;
        case 'number':
          object[key] = parseFloat(value);
          break;
        case 'null':
          object[key] = null;
          break;
        default:
          object[key] = value;
      }
    }
  }
};

// Only iterating through selectors without a class of processed because
// of cases where loading multiple partials in templates where the data has already been processed.
Greenhouse.findAndConvertMetadata = function () {
  // Fix for IE8 browsers that don't support querySelectorAll. If querySelectorAll is
  // not supported we use jQuery instead. We don't use jQuery for all cases in the first
  // place because this script is needed at certain points before jQuery is even loaded
  // in the app. One such case is for rollbar.
  if (typeof document.querySelectorAll === 'function') {
    var jsContainer = document.querySelectorAll('.js-metadata:not(.processed)');

    forEachElement(jsContainer, function (el, i) {
      var contentSecurityPolicyHelper = new Greenhouse.ContentSecurityPolicyHelper(
        el
      );

      contentSecurityPolicyHelper.setObject();

      addClass(el, 'processed');
    });
  } else if (typeof jQuery === 'function') {
    var $jsContainer = $('.js-metadata:not(.processed)');

    $jsContainer.each(function (i, el) {
      var contentSecurityPolicyHelper = new Greenhouse.ContentSecurityPolicyHelper(
        el
      );
      contentSecurityPolicyHelper.setObject();
      $(el).addClass('processed');
    });
  }

  function forEachElement(elements, fn) {
    for (var i = 0; i < elements.length; i++) {
      fn(elements[i], i);
    }
  }

  function addClass(el, className) {
    if (el.classList) {
      el.classList.add(className);
    } else {
      el.className += ' ' + className;
    }
  }
};

Greenhouse.findAndConvertMetadata();
